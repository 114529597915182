import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { message } from 'antd';

import * as API from '../../api';
import { DatePicker, DictFormActions, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm } from '../../hooks';
import { ROL, SiparisModel, UrunModel } from '../../types';

const OrderForm = z.object({
  kullaniciId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  krediKartiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  depoyeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  indirim: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  siparisNo: z.string().min(1, 'Zorunludur'),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciAciklama: z.string().optional(),
  adminAciklama: z.string().optional(),
  urunCesiti: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, "Ürün Çeşiti 1'den büyük olmalı."),
});

export const NewOrder = ({ onSaveDone = () => null, fillOrder, fillOrderTedHome, indirim, lock }: { onSaveDone?: CallableFunction; fillOrder?: SiparisModel | null, fillOrderTedHome?: UrunModel[] | null, indirim? : string, lock? : boolean  }) => {
  const kullaniciModel = useContext(UserContext);
  const isAdmin = kullaniciModel.kullaniciTipi === ROL.ADMIN;

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [karts = []] = API.KART.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehousesTed = []] = API.WAREHOUSES.useFetchActiveTed({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear, fill } = useFillForm(OrderForm, {
    kullaniciId: kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId,
    krediKartiId: undefined,
    depoyeriId: undefined,
    indirim: '500TL',
    siparisNo: '',
    siparisTarihi: new Date(),
    tedarikciAciklama: '',
    urunCesiti: 1,
  });

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    clear();
    if (fillOrder) {
      fill({
        pazaryeriId: fillOrder.bhrPazaryeriModel?.pazaryeriId,
        kategoriId: fillOrder.bhrKategoriModel?.kategoriId,
        markaId: fillOrder.bhrMarkaModel?.markaId,
        urunId: fillOrder.bhrUrunModel?.urunId,
        depoyeriId: fillOrder.bhrDepoyeriModel?.depoyeriId,
        alisFiyat: fillOrder.alisFiyat,
        tedarikciAciklama: fillOrder.tedarikciAciklama || '',
        siparisTarihi: fillOrder.siparisTarihi ? new Date(fillOrder.siparisTarihi) : new Date(),
        adet: 1,
        urunCesiti: 1,
      });
    }
  }, [fillOrder?.siparisId]);

  useEffect(() => {
    clear();
    if (fillOrderTedHome && fillOrderTedHome.length > 0) {
      const list = fillOrderTedHome.map((urun, index) => {
        return {
          ['cesit_urunId'+index] : urun.urunId,
          ['cesit_adet'+index] : 1,
        }
      })
      const partValue = Object.fromEntries(list.map(item => Object.entries(item)).flat())

      fill({
        siparisTarihi: new Date(),
        kullaniciId: kullaniciModel.kullaniciId,
        indirim: indirim,
        urunCesiti: fillOrderTedHome.length,
        ...partValue
      });
    }
  }, [indirim, fillOrderTedHome, kullaniciModel]);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const siparisTarihi = new Date(data.siparisTarihi);
      siparisTarihi.setHours(0);
      siparisTarihi.setMinutes(0);
      siparisTarihi.setSeconds(0);
      siparisTarihi.setMilliseconds(0);
      const formedData = {
        bhrPazaryeriModel: {
          pazaryeriId: 101,
        },
        bhrDepoyeriModel: {
          depoyeriId: data.depoyeriId,
        },
        siparisNo: data.siparisNo,
        siparisTarihi: siparisTarihi.toISOString(),
        tedarikciAciklama: data.tedarikciAciklama,
        firmaModel: kullaniciModel.firmaModel,
        bhrKrediKartiModel : {
          krediKartiId : data.krediKartiId
        },
        kullaniciModel: {
          kullaniciId: data.kullaniciId,
        },
        urunCesiti: data.urunCesiti,
      };

      const saveArray: SiparisModel[] = [];
      for (let i = 0; i < form.value['urunCesiti']; i++) {
        const kategoriId = 81;
        const markaId = 301;
        const urunId = form.value['cesit_urunId' + i];
        const adet = form.value['cesit_adet' + i];
        const alisFiyat = form.value['cesit_alisFiyat' + i];

        if (urunId && adet) {
          for (let j = 0; j < adet; j++) {
            const saveData = {
              ...formedData,
              bhrKategoriModel: {
                kategoriId: kategoriId,
              },
              bhrMarkaModel: {
                markaId: markaId,
              },
              bhrUrunModel: {
                urunId: urunId,
              },
              adet: adet,
              alisFiyat: getAlisFiyat(urunId, adet, data.indirim || ''),
            };

            saveArray.push(saveData);
          }
        } else {
          message.error('Ürün ve adet seçiniz.');
          return;
        }
      }

      await API.ORDERS.save(saveArray);

      onSaveDone();
      clear();
    } else {
      form.setAllTouched();
    }
  }, [form]);

  const cesitRange = Array.from({ length: Number(form.value.urunCesiti || 0) }).fill(0);

  const getAlisFiyat = useCallback(
    (urunId: number, adet: number, indirimTipi: string) => {
      const urun = products.find((item) => item.urunId === urunId);
      if (urun) {
        switch (indirimTipi) {
          case 'INDIRIMSIZ':
            return urun.alisFiyat;
          case '15YUZDE':
            return Math.round((urun.alisFiyat || 0) * 0.85);
          case '20YUZDE':
            let total2 = 0;
            for (let i = 0; i < cesitRange.length; i++) {
              const _urunId = form.value['cesit_urunId' + i];
              const _adet = form.value['cesit_adet' + i];
              const _urun = products.find((item) => item.urunId === _urunId);

              const f = Math.round((_urun?.alisFiyat || 0) * 0.80);

              total2 += _adet * f;
            }

            const ff =  Math.round((urun.alisFiyat || 0) * 0.80);

            const weight2 = ff / total2;
            const result2 = ff - 500 * weight2;

            return result2;
          case '500TL':
            let total = 0;
            for (let i = 0; i < cesitRange.length; i++) {
              const _urunId = form.value['cesit_urunId' + i];
              const _adet = form.value['cesit_adet' + i];
              const _urun = products.find((item) => item.urunId === _urunId);

              total += _adet * (_urun?.alisFiyat || 0);
            }

            const weight = ((urun.alisFiyat || 0) * 1) / total;
            const result = (urun.alisFiyat || 0) - 500 * weight;

            return Number.isNaN(result) ? 0 : Math.round(result);
          default:
            return urun.alisFiyat;
        }
      }

      return 0;
    },
    [products, form, cesitRange]
  );

  const kartByUser = karts?.filter((kart) => kart.kullaniciModel?.kullaniciId === form.value['kullaniciId']) || [];

  return (
    <div className="flex flex-col gap-2">
      <Card title="Yeni Sipariş Ekleme">
        <div className="flex gap-8">
          <div className="grid grid-cols-[100px_160px] grid-rows-[32px_32px_32px_32px_32px_32px_32px_32px_32px] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
            {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
              <>
                <label className="whitespace-nowrap">Tedarikçi :</label>
                <Select
                  options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
                  fieldNames={{ label: 'label', value: 'kullaniciId' }}
                  form={form}
                  name="kullaniciId"
                />
              </>
            )}

            <label className="whitespace-nowrap">Sipariş No :</label>
            <Input form={form} name="siparisNo" />

            <label className="whitespace-nowrap">Sipariş Tarih :</label>
            <DatePicker form={form} name="siparisTarihi" />

            <label className="whitespace-nowrap">Hesap Maili :</label>
            <Input form={form} name="tedarikciAciklama" />

            <label className="whitespace-nowrap">Kart :</label>
            <Select
              options={kartByUser}
              fieldNames={{ label: 'kartAdi', value: 'krediKartiId' }}
              searchFields={['kartAdi', 'kartNo', 'kartSahibi']}
              placeholder="Kart Adı, No veya sahibi..."
              form={form}
              name="krediKartiId"
            />

            <label className="whitespace-nowrap">Depoyeri :</label>
            <Select options={isAdmin ? warehouses : warehousesTed} fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }} form={form} name="depoyeriId" />

            <label className="whitespace-nowrap">İndirim :</label>
            <Select
              options={[
                { value: 'INDIRIMSIZ', label: 'İndirimsiz' },
                { value: '15YUZDE', label: '%15' },
                { value: '20YUZDE', label: '%20' },
                { value: '500TL', label: '500 TL' },
              ]}
              fieldNames={{ label: 'label', value: 'value' }}
              form={form}
              name="indirim"
              disabled={lock}
            />

            <label className="whitespace-nowrap">Ürün Çeşiti :</label>
            <InputNumber form={form} name="urunCesiti" min={1} disabled={lock} />

            <DictFormActions save={save} clear={clear} noClear={lock} />
          </div>
          <div className="max-h-[780px] gap-4 overflow-scroll grid grid-cols-3 grid-rows-[min-content] pr-6">
            {cesitRange.map((_, index) => (
              <div
                key={'cesit_' + index}
                className="h-max grid grid-cols-[70px_200px] grid-rows-[32px_32px_32px] items-center justify-start gap-y-6 gap-x-4 sm:max-w-sm p-2 bg-slate-100 rounded border border-solid border-slate-300"
              >
                <label className="whitespace-nowrap">Ürün :</label>
                <Select
                  options={products}
                  fieldNames={{ label: 'urunAdi', value: 'urunId' }}
                  searchFields={['barkodNo']}
                  placeholder="Ürün Adı veya Barkod"
                  form={form}
                  name={'cesit_urunId' + index}
                  disabled={lock}
                />

                <label className="whitespace-nowrap">Adet :</label>
                <InputNumber form={form} name={'cesit_adet' + index} min={1} disabled={lock} />

                <label className="whitespace-nowrap">Alış Fiyat :</label>
                <span>{getAlisFiyat(form.value['cesit_urunId' + index], form.value['cesit_adet' + index], form.value['indirim'] || '')} </span>
                {/*<InputNumber form={form} name={'cesit_alisFiyat' + index} min={0} />*/}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

NewOrder.path = 'new-order';
NewOrder.title = 'Yeni Sipariş';
NewOrder.group = 'supplier';
NewOrder.roles = [ROL.TEDARIKCI, ROL.ADMIN];
NewOrder.icon = <PlusCircleOutlined />;
